import React from "react";
import styled from "styled-components";
import Header from "../components/header";
import { device } from "../Helper/device";
import Footer from "../components/footer";

const Title = styled.h1`
  margin-top: 50px;
  text-align: center;
`;

const FormWrap = styled.div`
  margin: 0 20px;
  @media ${device.tablet} {
    margin: 0 40px;
  }
  @media ${device.desktop} {
    max-width: 1080px;
    margin: 0 auto;
  }
`;

const ContactWrap = styled.div`
  width: 100%;
`;
const ContactTitle = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
const DetailText = styled.textarea`
  width: 100%;
  border: 0;
  font-size: 1.3em;
  border-radius: 8px;
  border: solid 1px #ccc;
  margin: 0 0 20px;
  @media ${device.tablet} {
  }
  @media ${device.desktop} {
  }
`;
const Input = styled.input`
  width: 100%;
  border: 0;
  padding: 10px 0;
  font-size: 1.3em;
  border-radius: 8px;
  border: solid 1px #ccc;
  margin: 0 0 20px;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;
const SubmitButton = styled.button`
  user-select: none;
  border: none;
  border-radius: 2px;
  display: inline-block;
  font-weight: bold;
  width: 360px;
  cursor: pointer;
  color: #ffffff;
  background-color: #006570;
  font-size: 1.4rem;
  height: 36px;
  line-height: 34px;
  border-radius: 6px;
`;

const ContactTest = () => {
  return (
    <div>
      <Header />
      <Title>お問い合わせ</Title>
      <FormWrap>
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <ContactWrap className="form-group">
            <ContactTitle>お名前</ContactTitle>
            <Input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="お名前"
              required
              autocomplete="name"
            />
          </ContactWrap>
          <ContactWrap>
            <ContactTitle>会社名</ContactTitle>
            <Input
              type="text"
              className="form-control"
              id="company"
              name="company"
              placeholder="会社名"
              autocomplete="company"
            />
          </ContactWrap>
          <ContactWrap>
            <ContactTitle>電話番号</ContactTitle>
            <Input
              type="tel"
              className="form-control"
              id="tel"
              name="tel"
              placeholder="電話番号"
              // pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              required
              autocomplete="tel"
            />
          </ContactWrap>

          <ContactWrap className="form-group">
            <ContactTitle>メールアドレス</ContactTitle>
            <Input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="メールアドレス"
              // pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              required
              autocomplete="email"
            />
          </ContactWrap>
          <ContactWrap className="form-group">
            <ContactTitle>
              お問い合わせ内容
              <DetailText
                className="form-control"
                id="contact"
                name="content"
                rows="8"
                required
              ></DetailText>
            </ContactTitle>
          </ContactWrap>

          <ButtonWrap className="form-group">
            <SubmitButton type="submit">送信</SubmitButton>
          </ButtonWrap>
        </form>
      </FormWrap>
      <Footer />
    </div>
  );
};
export default ContactTest;
