import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { device } from "./../Helper/device";
import Mail from "./Logo/Mail";
import Phone from "./../images/phone.svg";
const Wrap = styled.div`
  width: 100%;
`;

const FooterItemWrap = styled.div`
  @media ${device.tablet} {
  }
`;

const CorpName = styled.div`
  text-align: center;
  color: #888888;
  font-size: 24px;
  padding: 20px 0;
  font-weight: bold;
  @media ${device.tablet} {
    text-align: center;
  }
`;
const FooterMenu = styled.div`
  display: none;
  @media ${device.tablet} {
    display: initial;
    margin: auto;
  }
`;
const MenuWrap = styled.div`
  @media ${device.tablet} {
    display: flex;
    :first-child {
      margin-bottom: 20px;
      justify-content: space-around;
    }
  }
`;

const Menu = styled(Link)`
  text-decoration: none;
  color: #888888;
  font-weight: bold;
  @media ${device.tablet} {
    margin-left: 10px;
    display: block;
  }
`;

const CorpInformation = styled.div`
  @media ${device.tablet} {
    margin-right: 20px;
    display: initial;
  }
`;

const ContactWrap = styled.div`
  width: 100%;
  text-align: center;
`;
const AddressBox = styled.div`
  padding-bottom: 10px;
`;
const AddressLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  width: 254px;
  margin: 0 auto 10px;
  background: #fff;
  color: #888888;
  text-align: center;
  text-decoration: none;
  line-height: 55px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border-radius: 15px;
`;
const LinkText = styled.span`
  margin-left: 8px;
`;
const Address = styled.span``;

const TelBox = styled.div`
  color: #888888;
`;
const TELNumber = styled.span`
  font-size: 36px;
  margin: 0;
`;
const StyledImage = styled.img`
  width: 70px;
  height: 70px;
`;

const Footer = () => {
  return (
    <Wrap>
      <FooterItemWrap>
        <CorpInformation>
          <CorpName>株式会社八坂堂</CorpName>
          <ContactWrap>
            <AddressLink to={"/contact"}>
              <Mail />
              <LinkText>お問い合わせ</LinkText>
            </AddressLink>
            <AddressBox>
              <Address>福岡県宗像市ひかりが丘6丁目2番地36</Address>
            </AddressBox>
          </ContactWrap>
        </CorpInformation>
      </FooterItemWrap>
    </Wrap>
  );
};

export default Footer;
